import React, {useEffect, useState} from 'react';
// import Logo from "../../images/logo.svg";
import Logo from "../../images/logo.svg";
import CustomButton from "../button";
import {Link} from "react-router-dom";
import Links from "./links";
import MobileMenu from "../mobilemenu";
import Wrapper from "../wrapper";
import {useTranslation} from "react-i18next";
import {Typography, TextField, Button, Box, FormControlLabel, Radio} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import axios from 'axios';

const Header = () => {
    const [mobile,setMobile] = useState(false)
    const [active,setActive] = useState(true)
    const [language,setLang] = useState('en')
    const [t, i18n] = useTranslation("global")


    useEffect(()=>{
        if(window.innerWidth <= 1366) {
            setMobile(true)
        }
    },[])
    const siteTitle = process.env.REACT_APP_PROJECT_NAME
    const siteMail = process.env.REACT_APP_SITE_MAIL
    return (
        <header className="w-screen bg-headerColor block mx-auto px-8 xl:px-16 py-1 fixed top-0 z-50">
            <Wrapper margins={''}>
                <div className="container  headerLinks mx-auto w-full flex items-center justify-between justify-items-center">
                    <div className='flex w-full flex-nowrap items-center xl:justify-start justify-between'>
                        <Link to="/">
                            <div onClick={()=>{window.scrollTo({top: 0, left: 0, behavior: 'smooth'});}} className='flex w-full items-center justify-center py-4'>
                                <img className='logo w-12 h-12' src={Logo} alt=""/>
                                <h2 className='mx-2.5 text-xs hidden xl:block sm:text-lg xl:text-2xl font-bold'>{siteTitle}</h2>
                            </div>
                        </Link>
                        <div className="mobileMenu flex items-center justify-center xl:hidden">
                            <a className='after:content-mailIcon mailIcon flex items-center justify-center relative top-1  pr-6 ' href={`mailto:${siteMail}`} target='_blank' rel="noreferrer"></a>
                            <MobileMenu/>
                        </div>
                        <div className="xl:block hidden">
                            <div className="nav-items flex hidden xl:flex ">
                                <nav className='mx-2.5 flex items-center'>
                                    <Links />
                                </nav>
                            </div>
                        </div>

                    </div>

                    <a className='after:content-mailIcon mailIcon hidden xl:flex items-center justify-center relative top-1  pr-12 ' href={`mailto:${siteMail}`} target='_blank' rel="noreferrer"></a>
                    <span className={`hidden xl:block mr-4 w-4/12 ${active ? 'headerButton' : 'headerButtonES'}`}>
                        <CustomButton  text={t("form.getFreeCons")} btnwidth={'w-2/4'} linkTo={'/contact-us'} filled={false}/>
                    </span>
                </div>

            </Wrapper>
        </header>
    );
};

export default Header;