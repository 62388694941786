import React, {useEffect } from 'react';

const Wrapper = ({ children, margins= true ,classes=''}) => {
    let marginWrapper = ''
    margins ? marginWrapper = 'mt-24 mb-24' : marginWrapper = ''
    return (
        <section className={`container mx-auto w-full h-full ${marginWrapper} ${classes}`}>{children}</section>
    );
};

export default Wrapper;