import React from 'react';
import ClientForm from "../../components/form";
import Wrapper from "../../components/wrapper";
import {useTranslation} from "react-i18next";

const ContactUs = () => {
    const [t, i18n] = useTranslation("global")
    return (
        <>
            <Wrapper margins={true}>
                <div className="ContactUsContainer pt-12 w-full flex flex-col items-center justify-center">
                    <div className="flex flex-col w-10/12 items-center mx-auto justify-center ContactsUsTitle text-center mb-12">
                        <h1 className='w-full text-black font-extrabold text-4xl xl:text-7xl whitespace-pre-line'>{t("contact_us.titleBlack")}<span className=' font-extrabold text-4xl xl:text-7xl whitespace-pre-line text-accentFontColor'>{t("contact_us.titleYellow")}</span></h1>
                        <h3 className='text-grayFontColor text-xl font-semibold break-all  xl:w-3/5 mt-10'>{t("contact_us.subtitle")}</h3>
                    </div>
                    <ClientForm/>
                </div>
            </Wrapper>
        </>
    );
};

export default ContactUs;