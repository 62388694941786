import './App.css';
import Header from "./components/header";
import Footer from "./components/footer";
import Main from "./pages/Main";
import {Route, Routes} from "react-router-dom";
import Layout from "./components/layout";
import React from "react";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import Policy from "./pages/Policy"
import Terms from './pages/Terms'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useTranslation} from "react-i18next";
function App() {
    const [t, i18n] = useTranslation("global");
    return (
        <>
            <Header/>
                <Routes>
                    <Route path='/' element={<Main/>}/>
                    <Route path='/binary-options-scam' element={<Layout title={t("header.links.recovery.contents.binary.title")} page={'binary-options-scam'}/>}/>
                    <Route path='/cryptocurrency-scam' element={<Layout title={t("header.links.recovery.contents.crypto.title")} page={'cryptocurrency-scam'}/>}/>
                    <Route path='/forex-scam' element={<Layout title={t("header.links.recovery.contents.forex.title")} page={'forex-scam'}/>}/>
                    <Route path='/stock-trading-scam' element={<Layout title={t("header.links.recovery.contents.stock.title")} page={'stock-trading-scam'}/>}/>
                    <Route path='/credit-card-phishing-scam' element={<Layout title={t("header.links.recovery.contents.card.title")} page={'credit-card-phishing-scam'}/>}/>
                    <Route path='/property-scam' element={<Layout title={t("header.links.recovery.contents.property.title")} page={'property-scam'}/>}/>
                    <Route path='/romance-scam' element={<Layout title={t("header.links.recovery.contents.romance.title")} page={'romance-scam'}/>}/>
                    <Route path='/other-scam' element={<Layout title={t("header.links.recovery.contents.other.title")} page={'other-scam'}/>}/>
                    <Route path='/contact-us' element={<ContactUs/>}/>
                    <Route path='/about-us' element={<AboutUs/>}/>
                    <Route path='/privacy-policy' element={<Policy/>}/>
                    <Route path='/tnc' element={<Terms/>}/>
                </Routes>
            <Footer/>

        </>
    );
}

export default App;
