import React from 'react';
import {Link} from "react-router-dom";
import Wrapper from "../wrapper";
import DMCA from "../../images/dmca.png"
import {useTranslation} from "react-i18next";

const Footer = () => {
    const siteName = process.env.REACT_APP_PROJECT_NAME
    const siteMail = process.env.REACT_APP_SITE_MAIL
    const [t, i18n] = useTranslation("global");
    function handleClick() {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
    return (
        <div className="flex flex-col w-full h-full pb-12 bg-secondFontColor">
            <Wrapper margins={''}>
                <div className="flex w-full xl:w-12/12 flex-col xl:flex-row mx-auto justify-between aftFooterContainer xl:mt-12">
                    <div className="flex w-full xl:w-10/12 flex-col xl:flex-row items-center justify-center xl:items-start xl:justify-stretch">
                        <Link  onClick={handleClick} className='xl:ml-0 mt-10 xl:mt-0 whitespace-nowrap' to={'/contact-us'}>
                            {t("header.links.contactUs.title")}
                        </Link>
                        <Link onClick={handleClick} className='xl:ml-12   mt-10 xl:mt-0 whitespace-nowrap'  to={'/about-us'}>
                            {t("header.links.aboutUs.title")}
                        </Link>
                        <Link onClick={handleClick} className='xl:ml-12  mt-10 xl:mt-0 whitespace-nowrap' to={'/tnc'}>
                            {t("form.terms")}
                        </Link>
                        <Link onClick={handleClick} className='xl:ml-12 mt-10  xl:mb-0 xl:mt-0 whitespace-nowrap'  to={'/privacy-policy'}>
                            {t("header.links.policy.title")}
                        </Link>
                        <a className='before:content-mailIcon before:pt-2 ml-6 xl:ml-12 before:pr-2  xl:before:pt-0 xl:before:bottom-1 xl:before:relative mt-10 xl:mt-0 mb-5 xl:mb-0 mailIcon flex xl:items-start items-center justify-center relative pr-12 ' href={`mailto:${siteMail}`} target='_blank' rel="noreferrer">{siteMail}</a>

                    </div>
                    <div className="flex ml-6 w-10/12 whitespace-nowrap justify-center xl:justify-start">
                        © {siteName}
                        <img src={DMCA} className='ml-6 h-6' alt="Dmca Protected"/>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};

export default Footer;