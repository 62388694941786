import React from 'react'

import Wrapper from '../../components/wrapper'

const Policy = () => {
    const siteName = process.env.REACT_APP_PROJECT_NAME
    const url = window.location.hostname;

  return (
    <section>
        <Wrapper>
            <div className="flex mx-auto flex-col items-center justify-center text-black border-t border-b border-t-bg-mainBGColor border-b-bg-mainBGColor h-full w-full xl:w-9/12">
            <div className='w-8/12 pt-12 pb-6'>
              <h3 className='text-4xl font-bold pb-12 w-full'>Privacy Policy</h3>
              <span className=' text-xl pt-12 pb-12'>
                Your privacy is important to us. It is {url} policy to respect your privacy regarding any information we may collect from you across our website, {url}, and other sites we own and operate. We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we’re collecting it and how it will be used. We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we’ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorised access, disclosure, copying, use or modification. We don’t share any personally identifying information publicly or with third-parties, except when required to by law. Our website may link to external sites that are not operated by us.
                Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies. You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services. Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.
              </span>
                  <span className='text-xl pt-12 pb-12'>Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies. You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services. Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.</span>
                  </div>
            
            <div className='w-8/12 pt-6 pb-6'>
                  <h2 className="text-2xl font-bold pt-6 pb-6">
                    Overview
                  </h2>
                  <h3 className="text-xl">
                    This website uses cookies to improve your experience while you navigate through the website. Out of these cookies, the cookies that are categorized as necessary are stored on your browser as they are as essential for the working of basic functionalities of the website. We also use third-party cookies that help us analyze and understand how you use this website. These cookies will be stored in your browser only with your consent. You also have the option to opt-out of these cookies. But opting out of some of these cookies may have an effect on your browsing experience.
                  </h3>
            </div>
            <div className='w-8/12 pt-6 pb-6'>
                  <h2 className="text-2xl font-bold pt-6 pb-6">
                  Cookie policy
                  </h2>
                  <h3 className="text-xl">
                  Like most websites, our websites use cookies to collect information. Cookies are small data files which are placed on your computer or other devices (such as smartphones or tablets) as you browse our websites. They are used to ‘remember’ when your computer or device accesses our websites. Cookies are essential for the effective operation of our website and to help us further advertise our services.                  </h3>
            </div>
            <div className='w-8/12 pt-6 pb-6'>
                  <h2 className="text-2xl font-bold pt-6 pb-6">
                  Information collected
                  </h2>
                  <h3 className="text-xl">
                  Some cookies collect information about browsing when you access our websites via the same computer or device. This includes information about pages viewed, the browser you used and your journey around a website. All data passed by cookies is anonymous and will never contain individual detail such as your name, address or telephone number but may contain our customer reference number that is unique to you. For more detailed information about how cookies work                  </h3>
            </div>
            <div className='w-8/12 pt-6 pb-6'>
                  <h2 className="text-2xl font-bold pt-6 pb-6">
                  How are cookies managed
                  </h2>
                  <h3 className="text-xl pt-12 pb-12 flex flex-col">
                  The cookies stored on your computer or other device when you access our websites are designed by:
                  <span className="pl-6 pt-6 before:content-['◆'] before:text-accentFontColor text-xl before:pr-6" >third parties who participate with us in marketing programmes; and</span>   
                  <span className="pl-6 pt-6 pb-6 before:content-['◆'] before:text-accentFontColor text-xl before:pr-6" >third parties who participate with us in marketing programmes; and</span>                     
                  </h3>
            </div>
            <div className='w-8/12 pt-6 pb-6'>
                 <h2 className="text-2xl font-bold  pb-6">
                  What are cookies used for?
                  </h2>
                  <h3 className="text-xl pt-6 pb-12 flex flex-col">
                  The main purposes for which cookies are used are:     
                  <span className="pl-6 pt-6 before:content-['◆'] before:text-accentFontColor text-xl before:pr-6 " >For functional purposes essential to effective operation of our website, particularly in relation to site navigation and preferences.</span>   
                  <span className="pl-6 pt-6 before:content-['◆'] before:text-accentFontColor text-xl before:pr-6 " >For marketing and advertising, particularly web banner advertisements and targeted updates through digital channels and social media.</span>   
                  <span className="pl-6 pt-6 pb-6 before:content-['◆'] before:text-accentFontColor text-xl before:pr-6 " >For website analytics purposes, particularly services by Google such as Google Analytics and Google Tag Manager</span>   
                   </h3>
                   <h3 className="pt-2 text-xl pb-12">
                   By using this website, you consent to the processing of data about you by Google in the manner described in Google’s Privacy Policy <a href='https://www.google.com/policies/privacy/' className='underline' target='_blank' rel="noreferrer">(https://www.google.com/policies/privacy/)</a> and for the purposes set out above. You can opt out of Google Analytics if you disable or refuse the cookie, disable JavaScript, or use the opt-out service provided by Google <a href='https://tools.google.com/dlpage/gaoptout' className='underline' target='_blank' rel="noreferrer">(https://tools.google.com/dlpage/gaoptout)</a>.
                   </h3>
            </div>
            </div>
        </Wrapper>
    </section>
  )
}

export default Policy