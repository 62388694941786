import React, {useEffect, useRef, useState} from 'react';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { FormControl } from '@mui/base/FormControl';
import { useForm } from "react-hook-form";
import {Typography, TextField, Button, Box, FormControlLabel, Radio} from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { makeStyles } from "@mui/styles";
import Swal from 'sweetalert2';
import { TextareaAutosize } from '@mui/base';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import {useTranslation} from "react-i18next";
import {excelApi} from "../../variables/variables";

const ClientForm = ({inMainPage=false, formID='mainForm'}) => {
    const [t, i18n] = useTranslation("global")
    const useStyles = makeStyles(theme => ({
        inputLabelRoot: {
            color:"gray"
        },
        underline: {
            color: "gray",
        }
    }));
    const customTheme = (outerTheme) =>
        createTheme({
            palette: {
                mode: outerTheme.palette.mode,
            },
            components: {
                MuiTextField: {
                    styleOverrides: {
                        root: {
                            '--TextField-brandBorderColor': '#E0E3E7',
                            '--TextField-brandBorderHoverColor': '#B2BAC2',
                            '--TextField-brandBorderFocusedColor': '#6F7E8C',
                            '& label.Mui-focused': {
                                color: 'var(--TextField-brandBorderFocusedColor)',
                            },
                        },
                    },
                },
                MuiOutlinedInput: {
                    styleOverrides: {
                        notchedOutline: {
                            borderColor: 'var(--TextField-brandBorderColor)',
                        },
                        root: {
                            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                                borderColor: 'var(--TextField-brandBorderHoverColor)',
                            },
                            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
                                borderColor: 'var(--TextField-brandBorderFocusedColor)',
                            },
                        },
                    },
                },
                MuiFilledInput: {
                    styleOverrides: {
                        root: {
                            '&:before, &:after': {
                                borderBottom: '2px solid var(--TextField-brandBorderColor)',
                            },
                            '&:hover:not(.Mui-disabled, .Mui-error):before': {
                                borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
                            },
                            '&.Mui-focused:after': {
                                borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                            },
                        },
                    },
                },
                MuiInput: {
                    styleOverrides: {
                        root: {
                            '&:before': {
                                borderBottom: '2px solid var(--TextField-brandBorderColor)',
                            },
                            '&:hover:not(.Mui-disabled, .Mui-error):before': {
                                borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
                            },
                            '&.Mui-focused:after': {
                                borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                            },
                        },
                    },
                },
            },
        });
    const { register, handleSubmit, reset  } = useForm();
    const [phone, setPhone] = useState()
    const [formatPhone,setFormatPhone] = useState(0)
    const [ip, setIp] = useState("");
    const [city,setCity] = useState("")
    const [scam, setTypeScam] = useState('');
    const [country,setCountry] = useState('')
    const [much,setMuch] = useState('')
    const [lang,setLang] = useState('en')
    const [curIp,setCurIp] = useState()
    const phoneRef = useRef()
    useEffect(() => {
        axios.get("https://ipapi.co/json")
            .then(res => res)
            .then(data => {
                setCity(data.data.city)
                setIp(data.data.country)
                setCurIp(data.data.ip)
                setCountry(data.data.country_name)
            })
            .catch((err) => console.log(err));
        // fetch( "https://tracking.qxsmart.pro/api/v3/get-leads?api_token=IfkZ1eHhlgX2zxkc17ggsy2kMew3ZIrCbFkpDIAoQE11UFG9ssPCEW4Y2f5W",{
        //     method: "GET",
        //     mode: 'no-cors',
        //     headers: { 'Content-Type': 'application/json' },
        // }).then((e) => {
        //     console.log(e)
        // })

    },[])



    const handlePhone = (value, data, event, formattedValue) => {
        let phoneFormatValue = value.replace(data.dialCode,"p:+" + data.dialCode + "")
        setFormatPhone(phoneFormatValue)
        setPhone(value);
    };
    const handleScam = (event) => {
        setTypeScam(event.target.value)
    }

    const handleMuch = (event) => {
        setMuch(event.target.value);

    };
    const currentUrl = window.location.href;
    const formSubmit = (data) => {
        if (phone == undefined || formatPhone.length < 7 || phone == null || phone == '') {
                Swal.fire({
                    text: "Please type your phone number",
                    icon: "error"
                });
            return false
        }
        const formData = new FormData()
        formData.append('Name', data.Name)
        formData.append('Lname', data.LName)
        formData.append('EMail', data.EMail)
        formData.append('Phone', formatPhone)
        formData.append('Time', data.Time)
        formData.append('Country', country)
        formData.append('HowMuch', inMainPage ? much : data.HowMuch)
        formData.append('Case', '[' + scam + ']' + '__' + data.Case)
        formData.append('IP', curIp)
        formData.append('Source', window.location.href)
        formData.append('Language', i18n.resolvedLanguage)


        const formDataLead = new FormData()
        formDataLead.append('link_id', 200)
        formDataLead.append('fname', data.Name)
        formDataLead.append('lname', data.LName)
        formDataLead.append('email', data.EMail)
        formDataLead.append('fullphone', formatPhone)
        formDataLead.append('source', 'refundCO')
        formDataLead.append('ip', curIp)
        formDataLead.append('country', country)
        formDataLead.append('language', "ru")
        formDataLead.append('domain', currentUrl)
        //
        // axios
        //     .post("https://tracking.qxsmart.pro/api/v3/integration?api_token=IfkZ1eHhlgX2zxkc17ggsy2kMew3ZIrCbFkpDIAoQE11UFG9ssPCEW4Y2f5W", formDataLead,{
        //         headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        //     })
        //     .then((response) => console.log(response))
        //     .catch((error) => console.error(error));

        fetch( "https://tracking.qxsmart.pro/api/v3/integration?api_token=IfkZ1eHhlgX2zxkc17ggsy2kMew3ZIrCbFkpDIAoQE11UFG9ssPCEW4Y2f5W",{
            method: "POST",
            mode: 'no-cors',
            headers: { 'Content-Type': 'application/json' },
            body: formDataLead
        }).then((e) => {
            console.log(e)
        })



        //elnopy.adone.ai/api/v3/integration?
        fetch( excelApi,{
            method: "POST",
            body: formData
        }).then((e) => {
            reset()
            setPhone('')
            setMuch('')
            Swal.fire({
                title: "Success",
                text: "Expect a call from a staff member",
                icon: "success"
            })
        })
    }



    const [userCase, setUserCase] = useState('');

    const [name,setName] = useState('');
    const [lname, setLname] = useState('');

 
    const handleCase = (event) => {
        setUserCase(event.target.value)
    }
    const todayData = new Date().toLocaleDateString();
    const todayTime = new Date().toLocaleTimeString();
    const fullData = todayData + '_' + todayTime
    const userCity = city;
    const outerTheme = useTheme();
    const classes = useStyles();
    const styleInptel = inMainPage ? {width:'100%', marginBottom:'0.5rem',marginRight:'0.5rem'} : {width:'100%',marginLeft:'0', marginBottom:'0.5rem',marginRight:'0.8rem'}

    return (
        <>
            <ThemeProvider theme={customTheme(outerTheme)}>
                <Box className={`${inMainPage ? 'bg-white w-10/12  ' : 'w-full'} flex items-center justify-center `}>
                    <form className='w-full' id={formID}  onSubmit={handleSubmit(formSubmit)}>

                        <Box className="flex w-10/12 flex-col items-center justify-center mx-auto">
                            <Typography className={`${inMainPage ? "text-center text-secondFontColor pt-6 pb-6" : "hidden"}`}>
                                <h2 className='w-full text-secondFontColor font-bold text-2xl whitespace-pre-line'>{t("form.startHere")}</h2>
                                <h3 className='text-black text-md font-semibold w-full '>{t("form.secureafree")}</h3>
                            </Typography>
                            <Box  className={`flex ${inMainPage ? 'flex-col w-full' : 'w-full xl:w-6/12 xl:mx-auto'} items-center  justify-center text-black`}>
                                <Box className={`flex w-full items-center  justify-center`}>

                                <TextField
                                    style={styleInptel}
                                    type="text"
                                    required={true}
                                    label={t("form.firstName")}
                                    variant="outlined"
                                    name="name"
                                    id="name"
                                    inputProps={register('Name')}
                                />

                                    <TextField
                                    style={{width:'100%', marginBottom:'0.5rem'}}
                                    type="text"
                                    required={true}
                                    label={t("form.lastName")}
                                    inputProps={register('LName')}
                                    variant="outlined"
                                    id="LName"
                                    />

                                </Box>
                            </Box>
                            <Box className={`flex ${inMainPage ? 'flex-col w-full' : 'w-full xl:w-6/12 xl:mx-auto'} items-start justify-center`}>
                                <PhoneInput
                                    inputProps={{
                                        register: 'Phone',
                                        required: true,
                                        name:'phone',
                                    }}
                                    style={{width:'100%', marginBottom:'0.5rem', marginRight:'0.5rem'}}
                                    inputClass={'tel-input'}
                                    value={phone}
                                    enableLongNumbers={true}
                                    specialLabel={t("form.phone")}
                                    dropdownClass={'tel-dropdown'}
                                    containerClass={'tel-container'}
                                    enableAreaCode={true}
                                    countryCodeEditable={false}
                                    country={ip.toLowerCase()}
                                    onChange={handlePhone}
                                />
                                <TextField
                                    style={{width:'100%', paddingLeft: '0.2rem', marginBottom:'0.5rem'}}
                                    type="email"
                                    label={t("form.enteremail")}
                                    id="EMail"
                                    inputProps={register('EMail')}
                                    variant="outlined"
                                />
                                <TextField
                                    variant='filled'
                                    style={{width:'100%', marginBottom:'0.5rem',  marginRight:'0.5rem', display:inMainPage ? 'flex' : 'none'}}
                                    id="demo-simple-select"
                                    label={t("form.howMuch.title")}
                                    value={much}
                                    select
                                    inputProps={{MenuProps: {disableScrollLock: true},register:"HowMuch"}}
                                    className={classes.underline}
                                    onChange={(event)=>{handleMuch(event)}}
                                    placeholder={t("form.howMuch.title")}>
                                    <MenuItem className={'text-secondFontColor'} value="Less than $5,000">{t("form.howMuch.options.1")}</MenuItem>
                                    <MenuItem className={'text-secondFontColor'} value="$5,000 - $10,000"  >{t("form.howMuch.options.2")}</MenuItem>
                                    <MenuItem className={'text-secondFontColor'} value="$10,000 - $20,000"  >{t("form.howMuch.options.3")}</MenuItem>
                                    <MenuItem className={'text-secondFontColor'} value="$20,000 - $40,000"   >{t("form.howMuch.options.4")}</MenuItem>
                                    <MenuItem className={'text-secondFontColor'} value="$80,000 - $160,000"   >{t("form.howMuch.options.5")}</MenuItem>
                                    <MenuItem className={'text-secondFontColor'} value="$160,000 and up"  >{t("form.howMuch.options.6")}</MenuItem>
                                </TextField>
                            </Box>
                            <Box className={`flex ${inMainPage ? 'flex-col w-full' : 'w-full xl:w-6/12 xl:mx-auto'} items-center  justify-center text-black`}>
                                <FormControl className={`flex ${inMainPage ? 'hidden' : 'w-full items-center  justify-center'}`}>
                                    <TextField
                                        variant='filled'
                                        style={{width:'100%', marginBottom:'0.5rem',  marginRight:'0.5rem'}}
                                        id="demo-simple-select"
                                        label={t("form.selecttypescam")}
                                        value={scam}
                                        select
                                        inputProps={{MenuProps: {disableScrollLock: true},register:"Scam"}}
                                        className={classes.underline}
                                        onChange={(event)=>{handleScam(event)}}
                                        placeholder='Choose type of scam'>
                                        <MenuItem value={'Binary options'}>{t("header.links.recovery.contents.binary.title")}</MenuItem>
                                        <MenuItem value={'Cryptocurrency'}>{t("header.links.recovery.contents.crypto.title")}</MenuItem>
                                        <MenuItem value={'Forex'}>{t("header.links.recovery.contents.forex.title")}</MenuItem>
                                    </TextField>

                                <TextField
                                    style={{width:'100%', marginBottom:'0.5rem', marginLeft:'0.5rem'}}
                                    id="demo-multiple-name"
                                    placeholder={t("form.howMuch.title")}
                                    className={classes.underline}
                                    inputProps={register('HowMuch')}
                                    >

                                </TextField>
                                </FormControl>
                                </Box>
                            <FormControl className={`${inMainPage ? 'w-full flex justify-center' : 'w-full xl:w-6/12'}`}>
                                <TextareaAutosize style={{width:'100%', minHeight:'10vh',resize:'none',outline:'none'}} name="Case" className='border border-gray-200 p-4 text-secondFontColor w-full flex items-center justify-center' placeholder={t("form.outline")}  {...register('Case')} />
                            </FormControl>
                            <FormControl style={{display:'none'}}>
                            <TextField value={fullData} inputProps={register('Time')} />
                            <TextField value={userCity} inputProps={register('City')} />
                            {/* <TextField value={`[${scam}] \n ${userCase}`} inputProps={register('Case')} /> */}
                    </FormControl>
                        <FormControl className='w-full flex items-center justify-center'>
                            <Button className={`flex items-center justify-center ${inMainPage ? 'w-full mb-12 ' : 'w-6/12 items-center justify-center'}  mx-auto bg-accentFontColor hover:bg-accentHoverColor border-transparent text-secondFontColor border-2 сustomButton`}  variant="text" type="submit">
                                {t("form.getFreeCons")}
                           
                            </Button>
                        </FormControl>
                        </Box>
                    </form>
                </Box>
            </ThemeProvider>
        </>
    );
};

export default ClientForm;