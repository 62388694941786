import React from 'react'
import Wrapper from '../../components/wrapper'
const Terms = () => {
    const siteName = process.env.REACT_APP_PROJECT_NAME
    const url = window.location.hostname;
  return (
        <section>
            <Wrapper>
                <div className="flex mx-auto flex-col items-center justify-center text-black border-t border-b border-t-bg-mainBGColor border-b-bg-mainBGColor h-full w-full xl:w-9/12">
                
                <div className='w-8/12 pt-6 pb-6'>
                      <h2 className="text-2xl font-bold pt-6 pb-6">
                      1. Introduction
                      </h2>
                      <h3 className="text-xl">
                      These Website Standard Terms And Conditions (these “Terms” or these “Website Standard Terms And Conditions”) contained herein on this webpage, shall govern your use of this website, including all pages within this website (collectively referred to herein below as this “Website”). These Terms apply in full force and effect to your use of this Website and by using this Website, you expressly accept all terms and conditions contained herein in full. You must not use this Website, if you have any objection to any of these Website Standard Terms And Conditions.

This Website is not for use by any minors (defined as those who are not at least 18 years of age), and you must not use this Website if you a minor                      </h3>
                </div>
                <div className='w-8/12 pt-6 pb-6'>
                      <h2 className="text-2xl font-bold pt-6 pb-6">
                      2. Link to Other websites
                      </h2>
                      <h3 className="text-xl">
                      Our Service may contain links to third-party web sites or services that are not owned or controlled by {siteName}.

                      {siteName} has no control over, and assumes no responsibility for, the content, privacy policies, or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or practices of any third party web sites or services. You further acknowledge and agree that {siteName} shall not be responsible or services available on or through any such.                      </h3>
                </div>
                <div className='w-8/12 pt-6 pb-6'>
                      <h2 className="text-2xl font-bold pt-6 pb-6">
                      3. The Services
                      </h2>
                      <h3 className="text-xl">
                     {siteName} provides advice and guidance. The services are provided by and/or under the supervision of competent attorneys who are Israeli-licensed attorneys admitted to the Israel Bar Association. The services include recovery consulting, which constitutes assisting our clients through the procedure of cancelation and refund of transactions using an out-of-court alternative dispute resolution process. The company does not represent its clients. You can visit our Contact Us page for more information.                         </h3>
                </div>
                <div className='w-8/12 pt-6 pb-6'>
                      <h2 className="text-2xl font-bold pt-6">
                      4. Intellectual Property Rights
                      </h2>
                      <h3 className="text-xl pt-12 pb-12 flex flex-col">
                      Other than the content you own, which you may have opted to include on this Website, under these Terms, [{siteName}] its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved.                    
                      </h3>
                </div>
                <div className='w-8/12 pt-6 pb-6'>
                     <h2 className="text-2xl font-bold  ">
                     5. Restrictions
                      </h2>
                      <h3 className="text-xl pt-6 pb-12 flex flex-col">
                      You are expressly and emphatically restricted from all of the following:     
                      <span className="pl-6 pt-6 before:content-['◆'] before:text-accentFontColor text-xl before:pr-6 " >publishing any Website material in any media;</span>   
                      <span className="pl-6 pt-6 before:content-['◆'] before:text-accentFontColor text-xl before:pr-6 " >selling, sublicensing and/or otherwise commercializing any Website material;</span>   
                      <span className="pl-6 pt-6 before:content-['◆'] before:text-accentFontColor text-xl before:pr-6 " >publicly performing and/or showing any Website material;</span>   
                      <span className="pl-6 pt-6 before:content-['◆'] before:text-accentFontColor text-xl before:pr-6 " >using this Website in any way that is, or may be, damaging to this Website;</span>   
                      <span className="pl-6 pt-6 before:content-['◆'] before:text-accentFontColor text-xl before:pr-6 " >using this Website in any way that impacts user access to this Website;</span>   
                      <span className="pl-6 pt-6  pb-6 before:content-['◆'] before:text-accentFontColor text-xl before:pr-6 " >using this Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website, or to any person or business entity;</span>   

                       </h3>
                       <h3 className="pt-2 text-xl">
                       Certain areas of this Website are restricted from access by you and Money Back LTD may further restrict access by you to any areas of this Website, at any time, in its sole and absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality of such information.                       </h3>
                </div>
                <div className='w-8/12 pt-6 pb-6'>
                      <h2 className="text-2xl font-bold pt-6 ">
                      6. Your Content
                      </h2>
                      <h3 className="text-xl pt-12  flex flex-col">
                      In these Website Standard Terms And Conditions, “Your Content” shall mean any audio, video, text, images, personal information or other material you choose to display on this Website. With respect to Your Content, by displaying it, you grant {siteName} a non-exclusive, worldwide, irrevocable, royalty-free, sublicensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.                      </h3>
                </div>
                <div className='w-8/12 pt-6 pb-6'>
                      <h2 className="text-2xl font-bold pt-6 ">
                      7. No warranties
                      </h2>
                      <h3 className="text-xl pt-12  flex flex-col">
                     {siteName} makes no express or implied representations or warranties, of any kind related to this Website or the materials contained on this Website. Additionally, nothing contained on this Website shall be construed as providing consult or advice to you.                      </h3>
                </div>
                <div className='w-8/12 pt-6 pb-6'>
                      <h2 className="text-2xl font-bold pt-6">
                      8. Limitation of liability
                      </h2>
                      <h3 className="text-xl pt-12  flex flex-col">
                      In no event shall {siteName}, nor any of its officers, directors and employees, be liable to you for anything arising out of or in any way connected with your use of this Website, whether such liability is under contract, tort or otherwise, and {siteName}, including its officers, directors and employees shall not be liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.                      </h3>
                </div>
                <div className='w-8/12 pt-6 pb-6'>
                      <h2 className="text-2xl font-bold pt-6 ">
                      9. Indemnification
                      </h2>
                      <h3 className="text-xl pt-12  flex flex-col">
                      You hereby indemnify to the fullest extent {siteName} from and against any and all liabilities, costs, demands, causes of action, damages and expenses (including reasonable attorney’s fees) arising out of or in any way related to your breach of any of the provisions of these Terms.              
                      </h3>
                        </div>
                <div className='w-8/12 pt-6 pb-6'>
                      <h2 className="text-2xl font-bold pt-6 ">
                      10. Severability
                      </h2>
                      <h3 className="text-xl pt-12 pb-12 flex flex-col">
                      If any provision of these Terms is found to be unenforceable or invalid under any applicable law, such unenforceability or invalidity shall not render these Terms unenforceable or invalid as a whole, and such provisions shall be deleted without affecting the remaining provisions herein.
                      </h3>                </div>
                </div>
            </Wrapper>
        </section>
  )
}

export default Terms