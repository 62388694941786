import React, {useEffect, useState} from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
const images = require.context('../../images/carousel/', true);
const imageList = images.keys().map(image => images(image));

const CustomCarousel = ({children, widthContainer='600px', heightContainer='auto',sldShow= 4, sldScroll=4}) => {
    const [mobile,setMobile] = useState(false)

    useEffect(()=> {
        if (window.innerWidth <= 1366) {
            setMobile(true)
        }
    })
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: mobile ? 2 : sldShow,
        slidesToScroll:  mobile ? 2 : sldShow,
        arrows: mobile ? false : true
    };
    return (
        <div style={{width:mobile ? 'auto' : '600px'}} className={`mx-auto text-mainBGColor mt-12 mb-24 w-4/12 xl:w-2/4`}>
            <Slider {...settings} >
                {children ? children : imageList.map((image, index) => (
                        <img key={index} src={image} alt={''} />
                    ))}
            </Slider>
        </div>
    );
};

export default CustomCarousel;